<template>
  <v-container fluid>
    <v-row>
      <v-col />
    </v-row>
  </v-container>
</template>

<script>

  export default {
    props: {

    },

    data () {
      return {

      }
    },

    mounted () {

    },

    methods: {

    },

  }
</script>
